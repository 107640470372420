import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { Routes } from '../Routes';
import { Brands } from '../Brand';
import { AccessTypes } from '../GraphQL.User';
import ProtectedRoute from '../middleware/ProtectedRoute';

const LazyAutoPayEnrollmentSection = lazy(() => import('./auto-pay-enrollment/AutoPayEnrollmentSection'));
const LazyAmortizationScheduleSection = lazy(() => import('./amortization-schedule/AmortizationScheduleSection'));
const LazyCancelPaymentSection = lazy(() => import('./cancel-payment/CancelPaymentSection'));
const LazyLoanOutOfWallet = lazy(() => import('./funding-out-of-wallet/funding-out-of-wallet-section'));
const LazyLoanMakePayment = lazy(() => import('./make-payment/MakePaymentSection'));
const LazyLoanOutOfWalletTesting = lazy(() => import('../out-of-wallet-testing/out-of-wallet-testing-section'));
const LazyIndividualScheduledPayments = lazy(() => import('./individual-scheduled-payments/IndividualScheduledPaymentsSection'));
const LazyRefinanceDisclosuresSection = lazy(() => import('./refinance/RefinanceDisclosuresSection'));
const LazyBuyUpSectionCM = lazy(() => import('./buy-up/BuyUpSection.CashMoney'));
const LazyBuyUpSectionSRC = lazy(() => import('./buy-up/BuyUpSection.SpeedyCash'));
const LazyGetCashSection = lazy(() => import('./get-cash/GetCashSection'));
const LazyRefinanceSRC = lazy(() => import('./refinance/RefinanceSection.SpeedyCash'));
const LazyRefinanceCM = lazy(() => import('./refinance/RefinanceSection.CashMoney'));
const LazyRefinanceIncomeVerificationSection = lazy(() => import('./refinance-income-verification/RefinanceIncomeVerificationSection'));
const LazyRepayment = lazy(() => import('./repayment/RepaymentSection'));
const LazyLoanComplete = lazy(() => import('./complete/CompleteSection'));
const LazyLoanInteracFunded = lazy(() => import('./interac/InteracFundedSection'));
const LazyPaymentReauthorization = lazy(() => import('./payment-reauthorization/PaymentReauthorizationSection'));
const LazyLoanDocumentSection = lazy(() => import('./documents/LoanDocumentsSection'));
const LazyPendingMilitaryReview = lazy(() => import('../user/pending-military-review/PendingMilitaryReview'));
const LazyPaymentPlanRequest = lazy(() => import('./payment-plan-request/PaymentPlanRequestSection'));
const LazyPaymentPlanRequestComplete = lazy(() => import('./payment-plan-request/PaymentPlanRequestComplete'));

export function getLoanRoutes(brand: Brands) {
    return (
        <>
            <Route element={<ProtectedRoute allowedAccessTypes={[AccessTypes.Customer, AccessTypes.Payment]} />}>
                <Route path={Routes.Loan.CancelPayment} element={<LazyCancelPaymentSection />} />
                <Route path={Routes.Loan.MakePayment} element={<LazyLoanMakePayment />} />
            </Route>
            <Route element={<ProtectedRoute allowedAccessTypes={[AccessTypes.Customer, AccessTypes.Refinance]} />}>
                <Route path={Routes.Loan.Refinance} element={brand === Brands.CashMoney ? <LazyRefinanceCM /> : <LazyRefinanceSRC />} />
                <Route path={Routes.Loan.RefinanceIncomeVerification} element={<LazyRefinanceIncomeVerificationSection />} />
                <Route path={Routes.Loan.RefinanceDisclosures} element={<LazyRefinanceDisclosuresSection />} />
            </Route>
            <Route element={<ProtectedRoute allowedAccessTypes={[AccessTypes.Customer, AccessTypes.BuyUp]} />}>
                <Route path={Routes.Loan.BuyUp} element={brand === Brands.CashMoney ? <LazyBuyUpSectionCM /> : <LazyBuyUpSectionSRC />} />
            </Route>
            <Route element={<ProtectedRoute allowedAccessTypes={[AccessTypes.Customer]} />}>
                <Route path={Routes.Loan.AmortizationSchedule} element={<LazyAmortizationScheduleSection />} />
                <Route path={Routes.Loan.AutoPayEnrollment} element={<LazyAutoPayEnrollmentSection />} />
                <Route path={Routes.Loan.Complete} element={<LazyLoanComplete />} />
                <Route path={Routes.Loan.Funded} element={<LazyLoanInteracFunded />} />
                <Route path={Routes.Loan.GetCash} element={<LazyGetCashSection />} />
                <Route path={Routes.Loan.IndividualScheduledPayments} element={<LazyIndividualScheduledPayments />} />
                <Route path={Routes.Loan.OutOfWallet} element={<LazyLoanOutOfWallet />} />
                <Route path={Routes.Loan.OutOfWalletTesting} element={<LazyLoanOutOfWalletTesting />} />
                <Route path={Routes.Loan.PaymentPlanRequest} element={<LazyPaymentPlanRequest />} />
                <Route path={Routes.Loan.PaymentPlanRequestComplete} element={<LazyPaymentPlanRequestComplete />} />
                <Route path={Routes.Loan.PendingMilitaryReview} element={<LazyPendingMilitaryReview />} />
                <Route path={Routes.Loan.PaymentReauthorization} element={<LazyPaymentReauthorization />} />
                <Route path={Routes.Loan.Repayment} element={<LazyRepayment />} />
            </Route>
            <Route element={<ProtectedRoute allowedAccessTypes={[AccessTypes.Customer, AccessTypes.Documents]} />}>
                <Route path={Routes.Loan.Documents} element={<LazyLoanDocumentSection />} />
            </Route>
        </>
    );
}
